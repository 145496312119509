@import url("../assets/global.css");

nav .topbar {
	display: flex;
	width: 100%;
	padding: 1.5rem 6.5rem;
	justify-content: space-between;
	align-items: center;
	max-width: 90rem;
}

nav {
	display: flex;
	width: 100%;
	padding: 0 auto;

	background-color: white;
	position: sticky;
	top: 0px;
	z-index: 1;

	background: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(12px);

	align-items: center;
	justify-content: center;
}

nav .topbar a {
	color: #2d2d2d;
	font-family: "Merriweather";
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	position: relative;
	text-decoration: none;
	opacity: 0.85;

	transition: 0.3s ease;
}
nav .topbar a:hover {
	/* transform: scale(1.02); */
	opacity: 0.95;
}

nav .topbar .logo {
	transition: 0.2s ease-in;
	opacity: 1;
}
nav .topbar .logo:hover {
	background: linear-gradient(91deg, #f00 0%, #d36500 50.91%, #c0f 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	transform: none;
}
nav .topbar .logo.active {
	text-decoration: none;
}

nav .topbar .nav-items-mobile {
	position: absolute;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 68px;
	left: 0;
	right: 0;

	display: none;
	background-color: white;
}
nav .topbar .nav-items-mobile a {
	display: flex;
	width: 100%;
	padding: 1.5rem 0.625rem;
	justify-content: center;
	align-items: center;
	background-color: white;
	opacity: 1;
}

nav .topbar a.active {
	text-decoration: underline;
	color: black;
	opacity: 1;
}

/* make it mobile responsive */
@media (max-width: 768px) {
	nav .topbar {
		padding: 1.25rem 1rem;
	}
}

/* --------------------------------------------------- */

footer {
	width: 100%;
	background: black;
	overflow: hidden;
}

footer .footer {
	display: flex;
	width: 90rem;
	padding: 8.75rem 6.5rem 2.5rem 6.5rem;
	flex-direction: column;
	align-items: center;
	gap: 15rem;
	max-width: 90rem;
	margin: 0 auto;
}
footer .footer .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
footer .footer .container .content {
	display: flex;
	flex-direction: row;
}
.fill {
	width: 100%;
}
footer .footer .container .content-inner {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
}

/* footer .footer .container .content-inner p.xs {
	width: 64%;
} */
footer .footer .container h2 {
	text-align: left;
}
footer .footer .footer-bottom {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	max-width: 90rem;
	width: 100%;
}

footer .footer .footer-bottom p {
	color: #fff;
	font-family: "Merriweather";
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
footer .footer .footer-bottom p i {
	font-style: italic;
	font-weight: 400;
}
footer .footer .footer-bottom a.email-footer {
	color: #fff;
	font-family: "Merriweather";
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;

	transition: 0.3s ease;

	text-decoration: none;
}
footer .footer .footer-bottom a.email-footer:hover {
	background: linear-gradient(91deg, #f00 0%, #d36500 50.91%, #c0f 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	transform: none;
}

@media (max-width: 768px) {
	footer .footer {
		display: flex;
		width: 100%;
		padding: 6.5rem 1rem 2.5rem 1rem;
		flex-direction: column;
		align-items: center;
		gap: 8.75rem;
	}
	footer .footer .container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 5rem;
		align-self: stretch;
	}
	footer .footer .container .fill {
		display: none;
	}
	footer .footer .container h2 {
		text-align: center;
	}
	footer .footer .container .content .content-inner {
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	footer .footer .footer-bottom {
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
		gap: 2.5rem;
	}
	footer .footer .footer-bottom .ending-footer {
		order: 2;
	}
	footer .footer .footer-bottom .email-footer {
		order: 1;
	}
}

.fancy-email {
	background: linear-gradient(91deg, #f00 0%, #d36500 50.91%, #c0f 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
