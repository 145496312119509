@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.notification-container {
	position: fixed;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);

	border-radius: 64rem;
	background: var(
		--fancy-gradient,
		linear-gradient(90deg, #f00 0%, #d36500 50.91%, #c0f 100%)
	);
	box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.25);
	margin-bottom: 1rem;
	padding: 1px;
}
.notification-inner-container {
	display: inline-flex;
	padding: 0.75rem 2rem;
	justify-content: center;
	align-items: center;
	color: #000;
	font-family: Merriweather;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.125rem; /* 128.571% */
	letter-spacing: -0.0175rem;

	background-color: white;
	border-radius: 64rem;
}
