.starting-section {
	display: flex;
	width: 100%;
	padding: 10rem 6.5rem 7.5rem 6.5rem;
	flex-direction: column;
	justify-content: left;
	gap: 8.75rem;
	max-width: 90rem;
	margin: 0 auto;
}

.about-section.full-page {
	display: flex;
	background: black;
	width: 100%;
	align-items: center;
	justify-content: center;
}
.about-section.full-page .about-section-content {
	display: flex;
	padding: 7.5rem 6.5rem;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	max-width: 90rem;
}
.about-section.full-page .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 2rem;
	width: 50%;
}
.about-section.full-page.white-bg {
	background-color: white;
}
.about-section.full-page.white-bg .content {
	width: 36%;
}
@media (max-width: 768px) {
	.starting-section {
		width: 100%;
		padding: 7.5rem 1rem 4rem 1rem;
		flex-direction: column;

		gap: 8.75rem;
	}
	.about-section-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 3.25rem;
		padding: 3rem 0 !important;
	}

	.about-section.full-page {
		display: flex;
		padding: 6.5rem 1rem;
		flex-direction: column;
		align-items: flex-start;
		gap: 3.25rem;
		align-self: stretch;
	}
	.about-section.full-page .content {
		width: 100%;
		gap: 1.5rem;
	}
	.about-section.full-page.white-bg .content {
		width: 100%;
	}
}
