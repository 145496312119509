/* import Prompt and merriweather google fonts */

@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;700&display=swap");

/* Path: src\assets\global.css */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	--fancy-gradient: linear-gradient(
		91deg,
		#f00 0%,
		#d36500 50.91%,
		#c0f 100%
	);
}

body {
	background: #f5f5f5;
	-webkit-font-smoothing: antialiased;
}

h1 {
	color: black;
	font-family: "Prompt";
	font-size: 8.75rem;
	font-style: normal;
	font-weight: 800;
	line-height: 8.75rem; /* 100% */
	text-transform: uppercase;
	/* margin: 0px; */
}

h2 {
	color: white;
	text-align: center;

	/* Heading - shadow */
	font-family: "Prompt";
	font-size: 6.125rem;
	font-style: normal;
	font-weight: 800;
	line-height: 6.125rem; /* 100% */
	text-transform: uppercase;
}

h3 {
	color: #000;
	font-family: "Prompt";
	font-size: 4.5rem;
	font-style: normal;
	font-weight: 800;
	line-height: 5.25rem; /* 116.667% */
	text-transform: uppercase;
}

h4 {
	color: white;
	font-family: "Prompt";
	font-size: 3.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-transform: uppercase;
}

h5 {
	color: white;
	font-family: "Prompt";
	font-size: 2.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.5rem; /* 100% */
	text-transform: uppercase;
}

.white-text {
	color: white;
	text-shadow: 0px 0px 100px 0px rgba(255, 255, 255, 0.2);
}
.black-text {
	color: black;
	text-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.2);
}

.fancy-text {
	background: var(
		--fancy-gradient,
		linear-gradient(90deg, #f00 0%, #d36500 50.91%, #c0f 100%)
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

p {
	color: #fff;
	font-family: "Merriweather", serif;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2.5rem;
	margin: 0;
	padding: 0;

	width: fit-content;
}

p.lg {
	font-size: 1.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2.5rem; /* 142.857% */
	letter-spacing: -0.035rem;
}
p.sm {
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2.25rem; /* 163.636% */
}
p.xs {
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2rem; /* 160% */
	letter-spacing: -0.025rem;
}

p.white {
	color: white;
}
p.black {
	color: black;
}

span.fancy {
	background: linear-gradient(91deg, #f00 0%, #d36500 50.91%, #c0f 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: "Merriweather";
	font-size: 1.375rem;
	font-style: italic;
	font-weight: 400;
	line-height: 2.25rem;
}

button {
	outline: none;
	border: none;
}

/* add mobile responsive media query */

@media (max-width: 768px) {
	h1 {
		font-size: 4.25rem;
		line-height: 4.25rem;
	}
	h2 {
		font-size: 3.5rem;
		line-height: 3.5rem;
	}
	h3 {
		font-size: 3.25rem;
		line-height: 3.25rem;
	}
	h4 {
		font-size: 2rem;
		line-height: 2rem;
	}
	h5 {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}
	p {
		font-size: 1.2rem;
		line-height: 1.95rem;
	}
	p.lg {
		font-size: 1.4rem;
		line-height: 2rem;
	}
	p.sm {
		font-size: 1.35rem;
		line-height: 2.2rem; /* 175% */
	}
	p.xs {
		font-size: 1rem;
		line-height: 1.75rem;
	}
	span.fancy {
		font-size: 1.35rem;
		line-height: 2.2rem;
	}
}
