.fixed-socials .social-icon {
	padding: 0.62rem;
	border-radius: 99px;

	width: 2.375rem;
	height: 2.375rem;

	background-color: white;
	border-image: linear-gradient(91deg, #f00 0%, #d36500 50.91%, #c0f 100%);
	border-width: 1px;
	border-style: solid;
	color: black;
}
.fixed-socials {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.25rem;

	position: fixed;
	right: 2rem;
	bottom: 4rem;
	z-index: 10;
}

.social-icon-container {
	display: inline-block;
	background: linear-gradient(91deg, #f00 0%, #d36500 50.91%, #c0f 100%);
	border-radius: 999px;
	padding: 1px;
	overflow: hidden;
	box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
}

/* --------------------------------------------- */

button.fancy {
	display: flex;
	padding: 1px;
	justify-content: center;
	align-items: center;
	background: linear-gradient(91deg, #f00 0%, #d36500 50.91%, #c0f 100%);
	border-radius: 999px;
	cursor: pointer;
	width: fit-content;
}
button.fancy .content {
	color: #000;
	font-family: "Merriweather";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.125rem;
	letter-spacing: -0.0175rem;

	display: flex;
	padding: 0.75rem 2rem;
	justify-content: center;
	align-items: center;
	border-radius: 999px;

	background-color: white;

	transition: 0.3s ease;
}

button.fancy .content.black-btn {
	color: white;
	background-color: black;
}
button.fancy .content.black-btn:hover {
	background: rgba(0, 0, 0, 0.84);
}

button.fancy .content.white-btn {
	color: black;
	background-color: white;
}
button.fancy .content.white-btn:hover {
	background: rgba(255, 255, 255, 0.94);
}

/* --------------------------------------------- */

.skills-animated {
	display: flex;
	padding: 4rem 0rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	align-self: stretch;

	background: #000;
	width: calc(100vw - 1.2rem);
	overflow: hidden;
}
.skills-animated .animated-skills-strip {
	display: flex;
	align-items: center;
	gap: 4rem;
	align-self: stretch;
	width: 100%;
}

.skills-animated .animated-skills-strip.line-1 {
	justify-content: flex-start;
}

.skills-animated .animated-skills-strip.line-2 {
	justify-content: flex-end;
}

/* --------------------------------------------- */

.work-card {
	display: flex;
	align-items: center;
	gap: 4rem;
}
.work-card img {
	width: 800px;
	height: 500px;
	object-fit: cover;
	object-position: center;
}
.work-card .content {
	display: flex;
	padding: 0px;
	flex-direction: column;
	align-items: flex-start;
	gap: 2rem;
}
.work-card .content .inner-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
}
.work-card.white .content .inner-content h5,
.work-card.white .content .inner-content p {
	color: black;
}
.work-card .content .inner-content p {
	color: #fff;
	font-family: "Merriweather";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem; /* 133.333% */
	letter-spacing: -0.0225rem;
}
.work-card .tag {
	display: flex;
	padding: 1px;
	justify-content: center;
	align-items: center;
	background: linear-gradient(91deg, #f00 0%, #d36500 50.91%, #c0f 100%);
	border-radius: 999px;
}
.work-card .tag .inner-tag {
	display: flex;
	padding: 0.375rem 0.75rem;
	justify-content: center;
	align-items: center;
	background-color: black;
	border-radius: 999px;
	color: #fff;
	font-family: Merriweather;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.125rem; /* 128.571% */
	letter-spacing: -0.0175rem;

	color: #fff;
	font-family: "Merriweather";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.125rem; /* 128.571% */
	letter-spacing: -0.0175rem;
}
.work-card.white .tag .inner-tag {
	background-color: white;
	color: black;
}

.work-card .link-project {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	color: white;
	text-decoration: none;
	font-family: "Merriweather";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.25rem; /* 125% */
	letter-spacing: -0.02rem;
	opacity: 0.6;

	transition: 0.3s ease;
}
.work-card .link-project:hover {
	opacity: 1;
	text-decoration: underline;
}
.work-card .link-project img {
	width: 1.25rem;
	height: 1.25rem;
	color: black;
}

.work-card .link-project .animated-icon {
	animation: move 1.4s linear infinite;
}
@keyframes move {
	0% {
		transform: translateX(0);
	}
	75% {
		transform: translateX(6px);
	}
	100% {
		transform: translateX(0);
	}
}

.image-masked {
	position: absolute; /* Added */
	top: 0; /* Added */
	left: 0; /* Added */
	width: 100%; /* Added */
	height: 100%; /* Added */
	mask-image: radial-gradient(
		circle at var(--x) var(--y),
		transparent 0%,
		transparent 120px,
		black 120px
	); /* Added */
}

/* ----------------------------------------------- */

.testimonial-card {
	display: flex;
	padding: 2.5rem 2.5rem 1.5rem 2.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 2rem;
	flex: 1 0 0;

	background: black;
	color: white;
}
.testimonial-card .testimonial-footer {
	display: flex;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
}
.testimonial-card .testimonial-footer .name {
	font-family: "Prompt";
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.2rem; /* 120% */
	text-transform: uppercase;
	color: white;
	width: fit-content;
}
.testimonial-card .testimonial-footer .title {
	color: #e8e8e8;
	font-family: "Merriweather";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.25rem; /* 142.857% */
	letter-spacing: 0.0175rem;
	width: fit-content;
}
.testimonial-card .testimonial-footer img {
	width: 2.8rem;
	height: 2.8rem;
	border-radius: 99px;
}

/* ------------------------------------------------ */

.experience-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
}
.experience-item .item-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 0.5rem;
}
.experience-item .item-content h6 {
	color: #000;
	font-family: Merriweather;
	font-size: 2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.75rem; /* 137.5% */
}
.experience-item .item-content p {
	color: #000;
	font-family: Prompt;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.875rem; /* 150% */
	text-transform: uppercase;
}
.experience-item .date {
	color: #000;
	font-family: Prompt;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 300;
	line-height: 1.5rem; /* 133.333% */
	text-transform: uppercase;
}

/* --------------------------------------- */

@media (max-width: 768px) {
	.skills-animated {
		width: 100%;
		padding: 2.5rem 0rem;
		gap: 1.5rem;
	}
	.skills-animated .animated-skills-strip {
		gap: 2rem;
		width: max-content;
	}
	/* .skills-animated .animated-skills-strip h4 {
		width: 100%;
	} */

	.work-card {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 2rem;
	}
	.work-card img {
		width: 100%;
		height: 240px;
		object-fit: cover;
		object-position: center;
		order: 1 !important;
	}
	.work-card .content {
		display: flex;
		padding: 0px;
		flex-direction: column;
		align-items: flex-start;
		gap: 1.25rem;
		order: 2 !important;
	}

	/* -------- */
	.experience-item .item-content h6 {
		font-size: 1.75rem;
		line-height: 2.375rem; /* 137.5% */
	}

	.experience-item .date {
		font-size: 0.875rem;

		line-height: 1.25rem; /* 142.857% */
	}

	.fixed-socials {
		right: 1rem;
		bottom: 2rem;
		gap: 0.85rem;
	}
}
