.hero {
	display: flex;
	width: 100%;
	height: 90vh;
	padding: 0rem 6.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2.5rem;
}

.hero .hero-content {
	display: flex;
	padding-bottom: 0px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 90rem;
	padding: 0 6.5rem;
}

.hero .hero-content p.sm {
	width: 35%;
}

.about-me-section {
	display: flex;
	padding: 6.5rem;
	justify-content: center;
	align-items: center;
	gap: 5rem;
	width: 80rem;
	margin: 0 auto;
}
.about-me-section .content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	width: auto;
}
/* .about-me-section .content p.large {
	width: 70%;
} */

.avatar {
	border-radius: 999px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.avatar .circular-text {
	position: absolute;
	animation: spin 14s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* ----------------------------------------- */

.featured-work {
	display: flex;
	padding: 7.5rem 6.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 6.5rem;

	background: black;
}

/* ---------------------------------------- */

.testimonial-section {
	display: flex;
	width: 90rem;
	padding: 6.5rem;
	flex-direction: row;
	align-items: center;
	gap: 4rem;
	max-width: 90rem;
	margin: 0 auto;
}
.testimonial-section .companies {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 2.5rem;
	grid-row-gap: 2.5rem;
	padding: 0 2rem;
}
.testimonial-section .companies img {
	height: 3.625rem;
}
.testimonial-section .testimonial-slider {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	gap: 1.25rem;
	flex: 1 0 0;
}
.testimonial-section .testimonial-slider .testimonial-buttons {
	display: flex;
	align-items: flex-start;
	gap: 0.75rem;
}
.testimonial-section .testimonial-slider .testimonial-buttons > div {
	width: 1rem;
	height: 1rem;
	background: #d9d9d9;
}

/* ----------------------------------------- */

.featured-work.full-page {
	background-color: white;

	padding: 10rem 6.5rem 7.5rem 6.5rem;
}
.featured-work.full-page h2 {
	padding-bottom: 4rem;
}
