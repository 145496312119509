@media (max-width: 768px) {
	.hero {
		display: flex;
		padding: 10rem 1rem;
		flex-direction: column;
		align-items: flex-start;
		gap: 2.5rem;
		width: 100%;
	}
	.hero .hero-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 2.5rem;
		align-items: flex-start;
		padding: 0px;
	}
	.hero .hero-content h1 {
		width: 100%;
		justify-content: left;
	}
	.hero .hero-content p.sm {
		width: 92%;
		text-align: left;
	}

	.about-me-section {
		display: flex;
		padding: 4rem 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2.5rem;
		width: 100%;
	}
	.about-me-section .content {
		align-items: center;
		justify-content: center;
	}
	.about-me-section .content p {
		text-align: center;
	}

	.featured-work {
		display: flex;
		padding: 6.5rem 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 5rem;
		align-self: stretch;
		width: 100%;
	}

	.testimonial-section {
		display: flex;
		width: 100%;
		padding: 6.5rem 1rem;
		flex-direction: column;
		align-items: center;
		gap: 4rem;
	}
	.testimonial-section .companies {
		padding: 0;
		grid-template-columns: repeat(1, 1fr);
		grid-column-gap: 2rem;
		grid-row-gap: 2rem;
		order: 2;
	}

	.featured-work.full-page {
		padding: 6rem 1rem;
		gap: 4rem;
	}
}
