.project-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: row;
	padding-bottom: 8rem;
}
.project-header .main-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
}
.project-header .main-content h2 {
	font-weight: 700;
}
.project-header .main-content p.subheading {
	width: 70%;
}
.project-header .side-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 1.25rem;

	width: fit-content;
	margin: auto 0;
}
.project-header .side-content .item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.25rem;
	width: 100%;
	width: max-content;
}
.project-header .side-content .item p.label {
	color: #000;
	font-family: "Merriweather";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.375rem; /* 122.222% */
	width: 100%;
}
.project-header .side-content .item p.value {
	color: #000;
	font-family: "Merriweather";
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 900;
	line-height: 2rem; /* 145.455% */
	width: 100%;
}

.project-img {
	width: 100%;
}
.project-img img {
	width: 100%;
	object-fit: cover;
	object-position: center;
}

.project-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	width: 100%;
	max-width: 90rem;
}
.project-body h5 {
	color: #000;
	font-family: Prompt;
	font-size: 2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.6rem; /* 100% */
	text-transform: none;
}
.project-body p,
li {
	color: black;

	font-family: Merriweather;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2rem; /* 166.667% */
}
.project-body li {
	margin-left: 1.75rem;
	margin-bottom: 0.85rem;
}

.project-page {
	display: flex;
	width: 100%;
	padding: 10rem;
	flex-direction: column;
	align-items: center;
	gap: 5rem;
	justify-content: center;
	background-color: white;
}
.project-page .project-content {
	padding: 0rem 6rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4rem;
	justify-content: center;
}
@media (max-width: 768px) {
	.project-page {
		padding: 5rem 1rem;
	}
	.project-header {
		flex-direction: column;
		align-items: center;
		gap: 2.5rem;
		width: 100%;
	}
	.project-header .main-content {
		align-items: flex-start;
	}
	.project-header .main-content h2 {
		font-size: 2.75rem;
		line-height: 2.75rem;
	}
	.project-header .main-content p.subheading {
		width: 100%;
	}
	.project-header .side-content {
		align-items: center;
	}
	.project-header .side-content .item {
		align-items: center;
	}
	.project-header .side-content .item p.label {
		text-align: center;
	}
	.project-header .side-content .item p.value {
		text-align: center;
	}
	.project-img {
		width: 100%;
	}
	.project-img img {
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
	.project-body {
		width: 100%;
		max-width: 100%;
	}
	.project-body h5 {
		text-align: center;
		font-size: 1.8rem;
		line-height: 2.4rem;
	}
	.project-body p,
	li {
		text-align: left;
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.project-body li {
		margin-left: 1.5rem;
	}

	.project-page .project-content {
		padding: 0rem;
	}
}
